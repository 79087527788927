var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.itemData
        ? _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-2",
                  attrs: { cols: "12", md: "9" }
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          {
                            staticClass:
                              "content-header-title float-left pr-1 mb-0"
                          },
                          [_vm._v(" Onboarding ")]
                        ),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper" },
                          [
                            _c(
                              "b-breadcrumb",
                              [
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { to: "/apps/reports/type" } },
                                  [
                                    _c("feather-icon", {
                                      staticClass: "align-text-top",
                                      attrs: { icon: "HomeIcon", size: "16" }
                                    })
                                  ],
                                  1
                                ),
                                _vm.$can("create", _vm.abilityRequired) ||
                                _vm.$can("update", _vm.abilityRequired)
                                  ? _c(
                                      "b-breadcrumb-item",
                                      {
                                        attrs: { to: "/apps/onboarding/list" }
                                      },
                                      [_vm._v(" Onboarding ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "b-breadcrumb-item",
                                  { attrs: { active: "" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.itemData.first_name) +
                                        " " +
                                        _vm._s(_vm.itemData.last_name) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.itemData !== null &&
      _vm.currentLoggedInUserData.cognito_group !== "Client_Viewer" &&
        _vm.currentLoggedInUserData.cognito_group !== "Client_Admin" &&
        _vm.currentLoggedInUserData.Is_onboarding === true
        ? _c(
            "b-card",
            [
              _c("b-card-header", [_vm._v(" Pre-Approval Notes ")]),
              _c("b-card-body", [
                _c("h5", {
                  staticClass: "text-warning",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.itemData.preapproval_notes.replace(/\n/g, "<br>")
                    )
                  }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.itemData !== null &&
      (_vm.$can("create", _vm.abilityRequired) ||
        _vm.$can("update", _vm.abilityRequired) ||
        (_vm.currentLoggedInUserData.cognito_group !== "Client_Viewer" &&
          _vm.currentLoggedInUserData.cognito_group !== "Client_Admin" &&
          _vm.currentLoggedInUserData.Is_onboarding === true))
        ? _c(
            "b-card",
            [
              _c("b-card-header", [
                _c("h5", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.itemTypeName) +
                      " Details - " +
                      _vm._s(_vm.itemData.username) +
                      " "
                  )
                ])
              ]),
              _c(
                "b-card-body",
                [
                  _c("validation-observer", {
                    ref: "observer",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var handleSubmit = ref.handleSubmit
                            return [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                      return handleSubmit(_vm.validateForm)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Personal Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Name",
                                                "label-for": "first_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "first_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .first_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "first_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.first_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "1" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Middle Initial",
                                                "label-for": "middle_initial"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "middle_initial"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "middle_initial"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .middle_initial,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "middle_initial",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.middle_initial"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Last Name",
                                                "label-for": "last_name"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "last_name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "last_name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .last_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "last_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.last_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Street",
                                                "label-for": "street"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "street",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "street"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .street,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "street",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.street"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "City",
                                                "label-for": "city"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "city",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "city"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .city,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "city",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.city"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Province",
                                                "label-for": "province"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "province",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.provinceOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "province"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .province,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "province",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.province"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Country",
                                                "label-for": "country"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "country",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.countryOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "country"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .country,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "country",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.country"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Postal Code",
                                                "label-for": "postal_code"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "postal_code",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "postal_code"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .postal_code,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "postal_code",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.postal_code"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Email",
                                                "label-for": "email"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "email",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "email"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .email,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "email",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.email"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Phone Number",
                                                "label-for": "phone_number"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "phone_number",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c("cleave", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "phone_number",
                                                                  raw: false,
                                                                  options:
                                                                    _vm.options
                                                                      .phone,
                                                                  placeholder:
                                                                    "123 123 1234"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .phone_number,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "phone_number",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.phone_number"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Birthdate",
                                                "label-for": "birth_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "birth_date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id: "birth_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .birth_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "birth_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.birth_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Gender",
                                                "label-for": "gender"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: { name: "gender" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              staticClass:
                                                                "radio-spacing",
                                                              attrs: {
                                                                options:
                                                                  _vm.genderOptions,
                                                                name: "gender",
                                                                stacked: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData
                                                                    .gender,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.itemData,
                                                                    "gender",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "itemData.gender"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Are you indigenous?",
                                                "label-for": "indian_status"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "indian_status"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              staticClass:
                                                                "radio-spacing",
                                                              attrs: {
                                                                options:
                                                                  _vm.indianStatusOptions,
                                                                name:
                                                                  "indian_status",
                                                                stacked: ""
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.itemData
                                                                    .indian_status,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.itemData,
                                                                    "indian_status",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "itemData.indian_status"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.itemData.indian_status
                                    ? _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v(
                                                "Indian Registration Information"
                                              )
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.itemData.indian_status
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Indian Registration Number",
                                                    "label-for":
                                                      "indian_reg_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "indian_reg_number"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "indian_reg_number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .indian_reg_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "indian_reg_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.indian_reg_number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.itemData.indian_status
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "INAC Serial Number",
                                                    "label-for":
                                                      "indian_inac_serial"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "indian_inac_serial"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "indian_inac_serial"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .indian_inac_serial,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "indian_inac_serial",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.indian_inac_serial"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.itemData.indian_status
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Indian Expiry Date",
                                                    "label-for":
                                                      "indian_exp_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "indian_exp_date",
                                                      rule:
                                                        String(
                                                          _vm.itemData.sin || ""
                                                        ).length > 0 &&
                                                        String(
                                                          _vm.itemData.sin
                                                        ).slice(0, 1) === "9"
                                                          ? "required"
                                                          : ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "indian_exp_date",
                                                                  config: {
                                                                    enableTime: true,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .indian_exp_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "indian_exp_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.indian_exp_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.itemData.indian_status
                                    ? _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "indian"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "indian"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  _c("b-row", [
                                    _vm.itemData.indian_image &&
                                    _vm.itemData.indian_image.access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData.indian_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData.indian_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData.indian_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .indian_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [_vm._v("SIN Information")])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "SIN Number",
                                                "label-for": "sin"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "sin",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "sin"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .sin,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "sin",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.sin"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "2" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "SIN Expiry Date",
                                                    "label-for":
                                                      "sin_expiry_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "sin_expiry_date",
                                                      rule:
                                                        String(
                                                          _vm.itemData.sin || ""
                                                        ).length > 0 &&
                                                        String(
                                                          _vm.itemData.sin
                                                        ).slice(0, 1) === "9"
                                                          ? "required"
                                                          : ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "sin_expiry_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .sin_expiry_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "sin_expiry_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.sin_expiry_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          id: "sin_image",
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "sin"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "sin"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.sin_image &&
                                    _vm.itemData.sin_image.access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData.sin_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData.sin_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData.sin_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .sin_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c(
                                        "b-row",
                                        {
                                          staticClass:
                                            "mt-2 mb-1 border-bottom-secondary"
                                        },
                                        [
                                          _c("b-col", [
                                            _c("h4", [
                                              _vm._v("Work Permit Information")
                                            ])
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c(
                                        "b-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Permit Number",
                                                    "label-for": "permit"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "permit",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id: "permit"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .permit,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "permit",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.permit"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "6" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Permit Expiry Date",
                                                    "label-for":
                                                      "permit_expiry_date"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "permit_expiry_date",
                                                      rules: "required"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c("flat-pickr", {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "permit_expiry_date",
                                                                  config: {
                                                                    enableTime: false,
                                                                    dateFormat:
                                                                      "Y-m-d"
                                                                  }
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.itemData
                                                                      .permit_expiry_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.itemData,
                                                                      "permit_expiry_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "itemData.permit_expiry_date"
                                                                }
                                                              }),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c("b-row", [
                                        _c(
                                          "section",
                                          [
                                            _c(
                                              "b-row",
                                              { staticClass: "mb-2 ml-0 mt-2" },
                                              [
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              id:
                                                                "permit_image",
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openCamera(
                                                                  "permit"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Take Picture "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.$can(
                                                  "update",
                                                  _vm.abilityRequired
                                                )
                                                  ? _c(
                                                      "b-col",
                                                      { attrs: { md: "6" } },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                              block: "",
                                                              disabled:
                                                                _vm.preventDoubleClick
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openImageSelector(
                                                                  "permit"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Add Image "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _vm._e(),
                                  String(_vm.itemData.sin || "").length > 0 &&
                                  String(_vm.itemData.sin).slice(0, 1) === "9"
                                    ? _c("b-row", [
                                        _vm.itemData.permit_image &&
                                        _vm.itemData.permit_image.access_url
                                          ? _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "grid-view ml-1 mt-2"
                                              },
                                              [
                                                _c(
                                                  "b-card",
                                                  {
                                                    key:
                                                      _vm.itemData.permit_image
                                                        .access_url,
                                                    ref:
                                                      _vm.itemData.permit_image
                                                        .access_url,
                                                    attrs: {
                                                      id:
                                                        _vm.itemData
                                                          .permit_image
                                                          .access_url,
                                                      "no-body": ""
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "item-img text-center"
                                                      },
                                                      [
                                                        _c(
                                                          "thumbs-lazy-image-component",
                                                          {
                                                            staticClass:
                                                              "card-img-top",
                                                            attrs: {
                                                              src:
                                                                _vm.itemData
                                                                  .permit_image
                                                                  .access_url,
                                                              "src-placeholder":
                                                                "@/assets/images/noImage.png"
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Banking Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Bank Number",
                                                "label-for": "bank_number"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "bank_number",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "bank_number"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .bank_number,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "bank_number",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.bank_number"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Transit Number",
                                                "label-for": "transit_number"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "transit_number",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "transit_number"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .transit_number,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "transit_number",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.transit_number"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Account Number",
                                                "label-for": "account_number"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "account_number",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "account_number"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .account_number,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "account_number",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.account_number"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "deposit"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "deposit"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.direct_deposit_image &&
                                    _vm.itemData.direct_deposit_image.access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .direct_deposit_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .direct_deposit_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .direct_deposit_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .direct_deposit_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Security Licence Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Security Licence Number",
                                                "label-for": "security_licence"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "security_licence",
                                                  rule: _vm.dynamicRequired
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "security_licence"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .security_licence,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "security_licence",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.security_licence"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Security Licence Expiry Date",
                                                "label-for":
                                                  "security_licence_exp_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "security_licence_exp_date",
                                                  rule: _vm.dynamicRequired
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "security_licence_exp_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .security_licence_exp_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "security_licence_exp_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.security_licence_exp_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "1" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Licence Class",
                                                "label-for":
                                                  "security_licence_class"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "security_licence_class",
                                                  rule: _vm.dynamicRequired
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "security_licence_class"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .security_licence_class,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "security_licence_class",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.security_licence_class"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            staticClass: "mb-1 ml-1 mt-2",
                                            attrs: {
                                              label: "Security Licence Image",
                                              "label-for":
                                                "security_licence_image"
                                            }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "security_licence_image",
                                                rule: _vm.dynamicRequired
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          id:
                                                            "security_licence_image",
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "security_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "security_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.security_licence_image &&
                                    _vm.itemData.security_licence_image
                                      .access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .security_licence_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .security_licence_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .security_licence_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .security_licence_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("First Aid Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Aid Agency Name",
                                                "label-for": "first_aid_agency"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_aid_agency"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "first_aid_agency"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .first_aid_agency,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "first_aid_agency",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.first_aid_agency"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Aid Type",
                                                "label-for": "first_aid_type"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_aid_type"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.firstAidTypeOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "first_aid_type"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .first_aid_type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "first_aid_type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.first_aid_type"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "First Aid Certificate Number",
                                                "label-for":
                                                  "first_aid_certificate"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_aid_certificate"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "first_aid_certificate"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .first_aid_certificate,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "first_aid_certificate",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.first_aid_certificate"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "First Aid Expiry Date",
                                                "label-for":
                                                  "first_aid_exp_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "first_aid_exp_date"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "first_aid_exp_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .first_aid_exp_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "first_aid_exp_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.first_aid_exp_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            staticClass: "mb-1 ml-1 mt-2",
                                            attrs: {
                                              label: "First Aid Image",
                                              "label-for": "first_aid_image"
                                            }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "first_aid_image",
                                                rule: _vm.dynamicRequired
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "first_aid"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "first_aid"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.first_aid_image &&
                                    _vm.itemData.first_aid_image.access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData.first_aid_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData.first_aid_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData.first_aid_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .first_aid_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Firearms Licence Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Firearms Licence Number",
                                                "label-for": "firearms_licence"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "firearms_licence"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "firearms_licence"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .firearms_licence,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "firearms_licence",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.firearms_licence"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Firearms Licence Expiry Date",
                                                "label-for":
                                                  "firearms_licence_exp_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "firearms_licence_exp_date"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "firearms_licence_exp_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .firearms_licence_exp_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "firearms_licence_exp_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.firearms_licence_exp_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Firearms Licence Authorization",
                                                "label-for":
                                                  "firearms_licence_auth"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "firearms_licence_auth"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.firearmsLicenceAuthOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "firearms_licence_auth"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .firearms_licence_auth,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "firearms_licence_auth",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.firearms_licence_auth"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c("b-form-group", {
                                          staticClass: "mb-1 ml-1 mt-2",
                                          attrs: {
                                            label: "Firearms Licence Image",
                                            "label-for":
                                              "firearms_licence_image"
                                          }
                                        }),
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "firearms_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "firearms_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.firearms_licence_image &&
                                    _vm.itemData.firearms_licence_image
                                      .access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .firearms_licence_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .firearms_licence_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .firearms_licence_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .firearms_licence_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Uniform Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Vest Size",
                                                "label-for": "uniform_vest"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_vest",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.uniformVestOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "uniform_vest"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_vest,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_vest",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_vest"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Shirt Size",
                                                "label-for": "uniform_shirt"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_shirt",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.uniformShirtOptions,
                                                              reduce: function(
                                                                val
                                                              ) {
                                                                return val.value
                                                              },
                                                              clearable: false,
                                                              "input-id":
                                                                "uniform_shirt"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_shirt,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_shirt",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_shirt"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Waist (inches)",
                                                "label-for":
                                                  "uniform_pants_waist"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_pants_waist",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "uniform_pants_waist"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_pants_waist,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_pants_waist",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_pants_waist"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Pant Length (inches)",
                                                "label-for":
                                                  "uniform_pants_length"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_pants_length",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "uniform_pants_length"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_pants_length,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_pants_length",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_pants_length"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Glove Size",
                                                "label-for": "uniform_glove"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_glove",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "uniform_glove"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_glove,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_glove",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_glove"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Hat Size",
                                                "label-for": "uniform_hat"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_hat",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "uniform_hat"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_hat,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_hat",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_hat"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Shoe Size (US Size)",
                                                "label-for": "uniform_shoe"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "uniform_shoe",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id: "uniform_shoe"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .uniform_shoe,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "uniform_shoe",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.uniform_shoe"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    {
                                      staticClass:
                                        "mt-2 mb-1 border-bottom-secondary"
                                    },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Proof of Identification")
                                        ]),
                                        _c("div", [
                                          _vm._v(" Please provide "),
                                          _c("strong", [_vm._v("TWO (2)")]),
                                          _vm._v(
                                            " of the following forms of identification:"
                                          ),
                                          _c("br"),
                                          _vm._v(" - Drivers Licence"),
                                          _c("br"),
                                          _vm._v(" - Passport"),
                                          _c("br"),
                                          _vm._v(" - Provincial Photo ID"),
                                          _c("br"),
                                          _vm._v(" - PAL Card"),
                                          _c("br"),
                                          _vm._v(" - Native Status"),
                                          _c("br"),
                                          _vm._v(
                                            " - Permanent Residence Card / Document"
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " - Record of Landing with photo"
                                          ),
                                          _c("br"),
                                          _c("br")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2 mb-1" },
                                    [
                                      _c("b-col", [
                                        _c("h4", [
                                          _vm._v("Drivers Licence Information")
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Drivers Licence Number",
                                                "label-for": "drivers_licence"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "drivers_licence"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "drivers_licence"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .drivers_licence,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "drivers_licence",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.drivers_licence"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Drivers Licence Issue Date",
                                                "label-for":
                                                  "drivers_licence_issue_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "drivers_licence_issue_date"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "drivers_licence_issue_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .drivers_licence_issue_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "drivers_licence_issue_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.drivers_licence_issue_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { md: "2" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label:
                                                  "Drivers Licence Expiry Date",
                                                "label-for":
                                                  "drivers_licence_exp_date"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name:
                                                    "drivers_licence_exp_date"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("flat-pickr", {
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              id:
                                                                "drivers_licence_exp_date",
                                                              config: {
                                                                enableTime: false,
                                                                dateFormat:
                                                                  "Y-m-d"
                                                              }
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .drivers_licence_exp_date,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "drivers_licence_exp_date",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.drivers_licence_exp_date"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { md: "1" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Licence Class",
                                                "label-for":
                                                  "drivers_licence_class"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "drivers_licence_class"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              id:
                                                                "drivers_licence_class"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.itemData
                                                                  .drivers_licence_class,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.itemData,
                                                                  "drivers_licence_class",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "itemData.drivers_licence_class"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "drivers_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "drivers_licence"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.drivers_licence_image &&
                                    _vm.itemData.drivers_licence_image
                                      .access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .drivers_licence_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .drivers_licence_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .drivers_licence_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .drivers_licence_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2 mb-1" },
                                    [
                                      _c("b-col", [
                                        _c("h5", [_vm._v("-- OR --")]),
                                        _c("h4", [
                                          _vm._v(
                                            "Additional Form of Identification"
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "identification1"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "identification1"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.identification1_image &&
                                    _vm.itemData.identification1_image
                                      .access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .identification1_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .identification1_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .identification1_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .identification1_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2 mb-1" },
                                    [
                                      _c("b-col", [
                                        _c("h5", [_vm._v("-- OR --")]),
                                        _c("h4", [
                                          _vm._v(
                                            "Additional Form of Identification"
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  ),
                                  _c("b-row", [
                                    _c(
                                      "section",
                                      [
                                        _c(
                                          "b-row",
                                          { staticClass: "mb-2 ml-0 mt-2" },
                                          [
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openCamera(
                                                              "identification2"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Take Picture ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.$can(
                                              "update",
                                              _vm.abilityRequired
                                            )
                                              ? _c(
                                                  "b-col",
                                                  { attrs: { md: "6" } },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                        attrs: {
                                                          variant: "primary",
                                                          block: "",
                                                          disabled:
                                                            _vm.preventDoubleClick
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.openImageSelector(
                                                              "identification2"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v(" Add Image ")]
                                                    )
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-row", [
                                    _vm.itemData.identification2_image &&
                                    _vm.itemData.identification2_image
                                      .access_url
                                      ? _c(
                                          "section",
                                          {
                                            staticClass: "grid-view ml-1 mt-2"
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                key:
                                                  _vm.itemData
                                                    .identification2_image
                                                    .access_url,
                                                ref:
                                                  _vm.itemData
                                                    .identification2_image
                                                    .access_url,
                                                attrs: {
                                                  id:
                                                    _vm.itemData
                                                      .identification2_image
                                                      .access_url,
                                                  "no-body": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "item-img text-center"
                                                  },
                                                  [
                                                    _c(
                                                      "thumbs-lazy-image-component",
                                                      {
                                                        staticClass:
                                                          "card-img-top",
                                                        attrs: {
                                                          src:
                                                            _vm.itemData
                                                              .identification2_image
                                                              .access_url,
                                                          "src-placeholder":
                                                            "@/assets/images/noImage.png"
                                                        }
                                                      }
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm.itemData !== null &&
                                  (_vm.$can("create", _vm.abilityRequired) ||
                                    _vm.$can("update", _vm.abilityRequired)) &&
                                  _vm.currentLoggedInUserData.Is_onboarding !==
                                    true
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Pre-Approval Notes",
                                                    "label-for":
                                                      "preapproval_notes"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "preapproval_notes"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-textarea",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "preapproval_notes",
                                                                    rows: "3",
                                                                    "max-rows":
                                                                      "6"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .itemData
                                                                        .preapproval_notes,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.itemData,
                                                                        "preapproval_notes",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "itemData.preapproval_notes"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    [
                                      _vm.allowApproval &&
                                      _vm.$can("delete", _vm.abilityRequired)
                                        ? _c(
                                            "b-row",
                                            { staticClass: "mt-4" },
                                            [
                                              _vm.$can(
                                                "update",
                                                _vm.abilityRequired
                                              )
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            type: "submit",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Save Changes "
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.itemData.is_approved
                                                ? _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleApprovalToggle
                                                          }
                                                        },
                                                        [_vm._v(" Approve ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "b-col",
                                                    { attrs: { md: "2" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          staticClass:
                                                            "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            block: "",
                                                            disabled:
                                                              _vm.preventDoubleClick
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleApprovalToggle
                                                          }
                                                        },
                                                        [_vm._v(" Unapprove ")]
                                                      )
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        : _vm.$can(
                                            "update",
                                            _vm.abilityRequired
                                          )
                                        ? _c(
                                            "b-row",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { md: "3" } },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "mb-1 mb-sm-0 mr-0 mr-sm-1",
                                                      attrs: {
                                                        variant: "primary",
                                                        block: "",
                                                        type: "submit",
                                                        disabled:
                                                          _vm.preventDoubleClick
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Proceed to Final Step "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3638585778
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("input", {
        ref: "cameraInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/*", capture: "environment" },
        on: { change: _vm.handleImageChange }
      }),
      _c("input", {
        ref: "imageInput",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/*" },
        on: { change: _vm.handleImageChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }